import { createApp , reactive } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

const globalState = reactive({
  isLoggedIn: null
});
const app = createApp(App);
app.config.globalProperties.$globalState = globalState; // Making the state globally accessible
loadFonts()

app.use(router).use(vuetify).mount('#app');


