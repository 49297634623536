<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div id="dropzone" class="custom-file mb-3">
          <input
            type="file"
            class="custom-file-input border border-primary"
            @change="handleFileUpload"
            id="customFile"
            accept=".csv"
          />
        </div>
        <div class="form-group">
          <label for="month">Month</label>
          <select class="form-control" v-model="selectedMonth" id="month">
            <option value="0">Month</option>
            <option v-for="i in 12" :value="i" :key="i">{{ i }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="year">Year</label>
          <select class="form-control" v-model="selectedYear" id="year">
            <option value="0">Year</option>
            <option v-for="year in years" :value="year" :key="year">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="brand">Brand</label>
          <select class="form-control" v-model="selectedBrand" id="brand">
            <option value="">Select Brand</option>
            <option value="sprizzy">Sprizzy</option>
            <option value="prodvigate">Prodvigate</option>
          </select>
        </div>
        <button class="btn btn-primary btn-block mt-4" @click="submitData">
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
export default {
  data() {
    return {
      selectedMonth: "",
      selectedYear: "",
      selectedBrand: "",
      years: [],
    };
  },
  created() {
    const currentYear = new Date().getFullYear();
    this.years = Array.from(
      { length: currentYear - 1899 },
      (v, k) => currentYear - k
    );
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;
      this.uploadCSV(file);
    },
    uploadCSV(file) {
      if (
        !file ||
        !this.selectedBrand ||
        !this.selectedMonth ||
        !this.selectedYear
      )
        return;

      const formData = new FormData();
      formData.append("file", file);

      axios
        .post(
          `https://adsbk.veefly.in/upload-csv/${this.selectedBrand}/${this.selectedMonth}/${this.selectedYear}`,
          formData
        )
        .then((response) => console.log(response.data))
        .catch((error) => console.error("Upload failed:", error));
    },
    submitData() {
      const fileInput = document.getElementById("customFile");
      const file = fileInput.files[0];
      this.uploadCSV(file);
    },
  },
};
</script>

<style>
/* Add your styles here */
#dropzone {
  color: #9b51e0;
  background-color: #e4d9ee;
  border-radius: 10px;
  border: 3px dashed #9b51e0;
  margin: 20px;
  padding: 50px;
  text-align: center;
  cursor: pointer;
}

.container {
  padding: 20px;
}

.custom-file-input {
  cursor: pointer;
}

.custom-file-label::after {
  content: "Browse";
}

.btn-primary {
  background-color: #9b51e0;
  border-color: #9b51e0;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.form-control {
  border-radius: 0.25rem;
}

/* Enhance the look of select dropdowns */
select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

/* Custom margin and padding for better spacing */
.mb-3 {
  margin-bottom: 1rem !important;
}

.btn-block {
  display: block;
  width: 100%;
}
</style>
