import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/UploadCsv.vue'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/LogIn.vue')
  },
  {
    path: '/register-for-adtransparency',
    name: 'register',
    component: () => import('../views/RegisterPage.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/ProfilePage.vue')
  },
  {
    path: '/addsdata',
    name: 'addsdata',
    component: () => import('../views/GoogleAddsData.vue')
  },
  {
    path: '/existingdata',
    name: 'existingdata',
    component: () => import('../views/GoogleAddsDataExisting.vue')
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
