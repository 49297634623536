<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-primary px-3">
    <!-- <router-link class="navbar-brand text-white" to="/"
      >Add Transparency</router-link
    > -->
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li v-if="isLoggedIn" class="nav-item">
          <div class="container borderYtoX 0">
            <router-link class="nav-link text-white sample" to="/home"
              >Home</router-link
            >
          </div>
        </li>
        <li v-if="!isLoggedIn" class="nav-item">
          <div class="container borderYtoX">
            <router-link class="nav-link text-white sample" to="/"
              >Login</router-link
            >
          </div>
        </li>
        <!-- <li v-if="!isLoggedIn" class="nav-item">
          <div class="container borderYtoX">
            <router-link class="nav-link text-white sample" to="/register-for-adtransparency"
              >Register</router-link
            >
          </div>
        </li> -->
        <li v-if="isLoggedIn" class="nav-item">
          <div class="container borderYtoX">
            <router-link class="nav-link text-white sample" to="/profile"
              >Profile</router-link
            >
          </div>
        </li>
        <li v-if="isLoggedIn" class="nav-item">
          <div class="container borderYtoX">
            <router-link class="nav-link text-white sample" to="/addsdata"
              >Update Data</router-link
            >
          </div>
        </li>
        <li v-if="isLoggedIn" class="nav-item">
          <div class="container borderYtoX">
            <router-link class="nav-link text-white sample" to="/existingdata"
              >Existing customer Data</router-link
            >
          </div>
        </li>
      </ul>
    </div>
    <form class="form-inline">
      <button @click="logout()" class="btn btn-success" type="button">
        <span v-if="isLoggedIn">LogOut</span>
        <span v-else>Please log in.</span>
      </button>
    </form>
  </nav>
</template>

<script>
import store from "@/store";

export default {
  data() {
    return {};
  },
  computed: {
    user() {
      return store.state.user.user
    },
    isLoggedIn() {
      return store.state.user.isLoggedIn
    },
  },
  created() {
    store.dispatch('user/checkIsLoggedIn')
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      store.dispatch('user/setUser', null)
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
/* Existing styles */
div.container a {
  color: #fff;
  text-decoration: none;
  /* font: 20px Raleway; */
  /* margin: 0px 10px; */
  padding: 0px 10px;
  position: relative;
  z-index: 0;
  cursor: pointer;
}
/* Custom borderYtoX styles */
div.borderYtoX .sample:before,
div.borderYtoX .sample:after {
  position: absolute;
  opacity: 0.5;
  height: 100%;
  width: 2px;
  content: "";
  background: #fff;
  transition: all 0.3s;
}

div.borderYtoX .sample:before {
  left: 0;
  top: 0;
}

div.borderYtoX .sample:after {
  right: 0;
  bottom: 0;
}

div.borderYtoX .sample:hover:before,
div.borderYtoX .sample:hover:after {
  opacity: 1;
  height: 2px;
  width: 100%;
}
</style>
