export default {
  namespaced: true,
  state: {
    user: null,
    isLoggedIn: false,
  },
  getters: {
    isLoggedIn: state => {
      return state.isLoggedIn ? state.isLoggedIn : localStorage.getItem("token") ? true : false
    },
  },
  mutations: {
    SET_USER(state, val) {
      state.user = val
    },
    SET_IS_LOGGED_IN(state, val) {
      state.isLoggedIn = val ? val : (localStorage.getItem("token") ? true : false);
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user)

      if (user) {
        commit('SET_IS_LOGGED_IN', true)
      } else {
        commit('SET_IS_LOGGED_IN', false)
      }
    },
    checkIsLoggedIn({ commit }) {
      const token = localStorage.getItem('token');
      commit('SET_IS_LOGGED_IN', Boolean(token));
    },
  },
}
