import { createStore } from 'vuex';

// Modules
import user from './user'

export default new createStore({
  modules: {
    user,
  },
  strict: true,
})
