<template>
  <v-app>
    <v-main>
      <NavBar/>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import NavBar from './components/NavBar'; // Make sure this matches the file name exactly

export default {
  components: {
    NavBar
  }
};
</script>
